import cspracingDefaults from '../cspracing/config.js';
import bigcommerceSparkDefaults from '../bigcommerce-spark/config.js';

export default {
  includes: ['bigcommerce-spark'],
  ...bigcommerceSparkDefaults,
  defaultView: 'productGrid',
  SearchRequestDefaults: {
    ...bigcommerceSparkDefaults.SearchRequestDefaults,
    pageSize: 12,
  },
  getLocalPreselection: cspracingDefaults.getLocalPreselection,
  fitmentSearch: {
    ...bigcommerceSparkDefaults.fitmentSearch,
    ...cspracingDefaults.fitmentSearch,
    categorySelectionPageRedirect: false, // remove it when moving to live script
  },
  Widgets: [
    ...bigcommerceSparkDefaults.Widgets.filter(
      (w) =>
        ![
          'SearchPage',
          'SearchHeader',
          'SearchBox',
          'HomeVehicleWidget',
          'FitmentTableTabDesktop',
          'FacetBrandTiles',
          'FacetCategoryTiles',
          'CategoryPage',
        ].includes(w.name),
    ),
    {
      name: 'SearchPage',
      location: { selector: '#cm_results', class: 'animate-loaded animated' },
      template: 'SearchPage',
    },
    {
      ...bigcommerceSparkDefaults.Widgets.find((widget) => widget.name === 'CategoryPage'),
      template: 'SearchPage',
    },
    {
      name: 'SearchBox',
      location: '#quickSearch',
      template: 'SearchBox',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'is_featured:desc': 'Featured Items',
        'date_created:desc': 'Newest Items',
        'total_sold:desc': 'Best Selling',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'review_score:desc': 'By Review',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
      sortSelectClass: 'form-select',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        firstChildOf: 'main .container',
        class: 'halo_MultiCategory cm_vehicle-widget__header-container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 850,
      initCollapsed: true,
    },
    {
      name: 'FitmentTableTabDesktop',
      type: 'FitmentTable',
      location: 'a.tab-title[href="#tab-fitment"]',
      template: 'fitmentSearch/fitmentTableTabDesktop',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands',
      template: 'facetTiles/alphabeticalContainer',
      view: 'columns', // FIXME: 'grid' view not yet styled for the Spark theme
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_categories',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () =>
        window.location.pathname === cspracingDefaults.fitmentSearch.categorySelectionPages[0].pathname,
    },
  ],
};
